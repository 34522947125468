import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import {Helmet} from "react-helmet";
import Footer from "../components/Layout/Footer";
import Navigation from "../components/Layout/Navigation/Navigation";
import '../../translation';
import { useTranslation } from 'react-i18next';

const VieScolaire = () => {
    const { t } = useTranslation('vieScolaire');
    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])

    return (
        <>
            <Navigation />
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>EIB Campus Pégomas - Vie Scolaire</title>
                    <meta name="description" content="L’établissement est ouvert du lundi au vendredi de 7h30 à 18h30. Le Mini-club accueille les enfants les mercredis et durant les vacances scolaires." />
                </Helmet>
                <Sidebar/>
                <div className='content fadeInEarth'>
                    <div className='special-title'>{t('vie-scolaire-title')}</div>
                    <p className='text text-center'>{t('vie-scolaire-t1')}</p><br/>
                    <div className='content-title mb-0'>
                        {t('vie-scolaire-t3')}
                    </div><br />
                    <p className='mb-0 pb-0 text' dangerouslySetInnerHTML={{__html: t('vie-scolaire-t4')}} />
                    <div className='page-divider' />
                    <div className='content-title mb-0'>
                        {t('vie-scolaire-t5')}
                    </div><br />
                    <p className='text'>
                        <strong>{t('vie-scolaire-t7')}</strong>
                    </p><br/>
                    <ul className='liste'>
                        <li><div className='dot' />{t('vie-scolaire-t8')}</li>
                    </ul>
                   <br />
                    <p className='text'>
                        <strong>{t('vie-scolaire-t13')}</strong><br />
                    </p><br/>
                    <ul className='liste'>
                        <li><div className='dot' />{t('vie-scolaire-t15')}</li>
                    </ul>
                    <div className='page-divider' />
                    <div className='content-title mb-0'>
                        {t('sb1')}
                    </div><br/>
                    <p className='text' dangerouslySetInnerHTML={{__html: t('sb2')}}/>
                    <div className='page-divider' />
                    <div className='content-title mb-0'>
                        {t('vie-scolaire-t17')}
                    </div><br />
                    <p className='text'>{t('vie-scolaire-t18')}</p>
                    <p className='text'>{t('v2')}</p>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default VieScolaire;